import { AccountBalanceWallet, Add, Business, Camera, Celebration, Close, Dashboard, Menu, Wallet } from "@mui/icons-material";
import { CircularProgress, Rating } from "@mui/material";
import { FirebaseApp } from "firebase/app";
import { User, UserCredential, createUserWithEmailAndPassword, getAuth, sendEmailVerification, signInWithEmailAndPassword } from "firebase/auth";
import { collection, DocumentSnapshot, getFirestore, doc as docu  } from "firebase/firestore";
import { getDatabase, ref as rtdbRef, child, get } from "firebase/database";
import { getDownloadURL, getStorage, ref, uploadString } from "firebase/storage";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useWindowDimensions from "../helper/dimension";
import { getData, getFS, getQuery, getUid, setFS } from "../helper/firehelpers";
import { BoldText, Btn, EditText, ErrorCont, getRawTime, getRootDB, HeadText, isEmlValid, LrText, manUrl, Mgin, MyCols, myEles, StripBtn, TextBox } from "../helper/general";
import Toast from "./toast/toast";
import logo from "../assets/logo.png"
import adev from "../assets/devicon.jpg"
import Webcam from "react-webcam";


function useQuery(){
    return new URLSearchParams(useLocation().search);
}

export default function Futa(mainprop:{isNgt:boolean,isApp:boolean}){
    const[complete, setComplete] = useState(false);
    const[edtable, setEditable] = useState(true);
    const[ran, setRan] = useState(false);
    const qry = useQuery();
    const dimen = useWindowDimensions()
    const navigate = useNavigate();
    const mye = new myEles(mainprop.isNgt);
    const mykey = Date.now()

    const[auth_stage,setAuthStage] = useState(0);//1-DONE, 2-GET-EML, 3-LOADING, 4-CONF-MAT, 5- CONF MAIL
    const[eml, setEml] = useState("")
    const[sn, setSn] = useState("")
    const[fn, setFn] = useState("")
    const[phn, setPhn] = useState("")
    const[mat, setMat] = useState("")
    const[portrait, setPortrait] = useState<any>()
    const[snap_mode, setSnapMode] = useState(0)
    const webcamRef = useRef<Webcam>(null);

    useEffect(()=>{
        if(mainprop.isNgt){
            document.body.classList.toggle("dark-theme")
        }
        if(!ran){
            setRan(true)
            if(localStorage.getItem('last_mat')!=null){
                setMat(localStorage.getItem('last_mat')!)
            }
        }
        setAuthStage(2)
    },[])


    const[load, setLoad]=useState(false)
    const[error, setError]=useState(false)
    const[toastMeta, setToastMeta] = useState({visible: false,msg: "",action:2,invoked:0})
    const[timy, setTimy] = useState<{timer?:NodeJS.Timeout}>({timer:undefined});
    function toast(msg:string, action:number,delay?:number){
      var _delay = delay || 5000
      setToastMeta({
          action: action,
          msg: msg,
          visible:true,
          invoked: Date.now()
      })
      clearTimeout(timy.timer)
      setTimy({
          timer:setTimeout(()=>{
              if(Date.now()-toastMeta.invoked > 4000){
                  setToastMeta({
                      action:2,
                      msg:"",
                      visible:false,
                      invoked: 0
                  })
              }
          },_delay)
      });
    }

    return (
        <div style={{
            backgroundColor:'lavender'
        }}  data-theme={mainprop.isNgt?'dark':'light'}>
            <ErrorCont isNgt={mainprop.isNgt} visible={error} retry={()=>{
    
            }}/>
            <div className="prgcont" style={{display:load?"flex":"none"}}>
                <CircularProgress/>
            </div>
            <Toast isNgt={mainprop.isNgt} msg= {toastMeta.msg} action={toastMeta.action} visible={toastMeta.visible} canc={()=>{
                    setToastMeta({
                        action:2,
                        msg:"",
                        visible:false,
                        invoked:0,
                    })
                }} />
            <div className="ctr" style={{
                width:dimen.width,
                height:dimen.height,
                margin:20
            }}>
                <Business className="icn" style={{
                    fontSize:30
                }}  />
                <Mgin top={20} />
                <mye.BTv text="Proceed To Library" size={20} />
                <Mgin top={20} />
                <mye.Tv text={`Please proceed to the library circulation unit to begin your registration`} />
            </div>
        </div>
    )

    // return (<div style={{
    //     backgroundColor:'lavender'
    // }}  data-theme={mainprop.isNgt?'dark':'light'}>
    //     <ErrorCont isNgt={mainprop.isNgt} visible={error} retry={()=>{

    //     }}/>
    //     <div className="prgcont" style={{display:load?"flex":"none"}}>
    //         <CircularProgress/>
    //     </div>
    //     <Toast isNgt={mainprop.isNgt} msg= {toastMeta.msg} action={toastMeta.action} visible={toastMeta.visible} canc={()=>{
    //             setToastMeta({
    //                 action:2,
    //                 msg:"",
    //                 visible:false,
    //                 invoked:0,
    //             })
    //         }} />
    //     <div style={{
    //         display:"flex",
    //         height:dimen.height,
    //         width:dimen.width
    //     }}>
    //         {complete?<div className="ctr" style={{
    //                 width:dimen.width,
    //                 height:dimen.height,
    //                 margin:20
    //             }}>
    //                 <Celebration className="icn" style={{
    //                     fontSize:30
    //                 }}  />
    //                 <Mgin top={20} />
    //                 <mye.BTv text="Registration Complete!" size={20} />
    //                 <Mgin top={20} />
    //                 <mye.Tv text={`You have completed the first stage of your registration. Proceed to the library's circulation unit to pick up your card and finish your registration.`} />
    //                 <Mgin top={20} />
    //                 {edtable?<Btn txt="EDIT FORM" onClick={()=>{
    //                     setComplete(false)
    //                     setAuthStage(1)
    //                 }} />:<mye.BTv  text="YOUR CARD IS READY" color={mye.mycols().green} size={16} />}
    //             </div>:snap_mode==0?auth_stage==0?
    //             <div className="ctr" style={{
    //                 width:dimen.width,
    //                 height:dimen.height
    //             }}>
    //                 <CircularProgress className="icn"/>
    //             </div>
    //         :auth_stage==1?
    //             <div style={{
    //                 margin:20,
    //                 backgroundColor:'lavender'
    //             }}>
    //                 <mye.HTv text="Albert Ilemobade Library, FUTA"/>
    //                 <Mgin top={10}/>
    //                 <mye.BTv text="100 Level Only" size={16}  />
    //                 <Mgin top={20}/>
    //                 <Info head="Library Card Registration" body="This is for 100 level students only. Upload clear and accurate details and proceed to the library to get your card once you are done"/>
    //                 <Mgin top={20}/>
    //                 <EditText hint="Your Surname" isNgt={mainprop.isNgt} min={3} max={20} value={sn} recv={(e)=>{
    //                     setSn(e.trim())
    //                 }} />
    //                 <Mgin top={10}/>
    //                 <EditText hint="Your First Name" isNgt={mainprop.isNgt} min={3} max={20} value={fn} recv={(e)=>{
    //                     setFn(e.trim())
    //                 }} />
    //                 <Mgin top={10}/>
    //                 <EditText hint="Your Phone Number" isNgt={mainprop.isNgt} min={11} max={11} value={phn} digi recv={(e)=>{
    //                     setPhn(e.trim())
    //                 }} />
    //                 <Mgin top={20}/>
    //                 <Info head="Note" body="You will now take a selfie of yourself (white background, this goes into your permanent record). Any error will render your registration invalid." />
    //                 <Mgin top={20}/>
    //                 <Icont src={portrait} txt="Click to snap yourself" ocl={()=>{
    //                     setSnapMode(2)
    //                 }}/>
    //                 <Mgin top={30}/>
    //                 <Btn txt="SUBMIT" onClick={()=>{
    //                     if(sn.length<3 || fn.length <3 || phn.length <11|| mat.length <11 || !phn.startsWith('0')){
    //                         toast('Please fill the form properly',0)
    //                         return;
    //                     }
    //                     if(mat.replace(/[^/]/g, "").length==2){
    //                         if(mat.substring(5,6)=="2"){
    //                             if(portrait){
    //                                 let surname = sn.toUpperCase()
    //                                 let firstname = fn.toUpperCase()
    //                                 let matric = mat.toUpperCase().replaceAll('/','')
    //                                 let phone = phn
    //                                 toast("Uploading.... Please hold on",2)
    //                                 setAuthStage(0)
    //                                 uploadString(ref(getStorage(),`${getRootDB()}/libreg/${matric}`),portrait,'data_url').then((snp)=>{
    //                                     getDownloadURL(snp.ref).then((durl)=>{
    //                                         setFS(docu(getFirestore(),`${getRootDB()}/libreg/${matric}`),{
    //                                             'e':getAuth().currentUser!.email,
    //                                             's':surname,
    //                                             'f':firstname,
    //                                             'p':phone,
    //                                             'dn':'0',
    //                                             'i':durl
    //                                         },(task)=>{
    //                                             if(task._success){
    //                                                 setComplete(true)
    //                                             }else{
    //                                                 setAuthStage(1)
    //                                                 toast('An error occured. Please try again',0)
    //                                             }
    //                                         })
    //                                     }).catch((e)=>{
    //                                         console.log(e)
    //                                         setAuthStage(1)
    //                                         toast('An error occured. Please try again',0)  
    //                                     })
    //                                 }).catch((e)=>{
    //                                     console.log(e)
    //                                     setAuthStage(1)
    //                                     toast('An error occured. Please try again',0)  
    //                                 })
    //                             }else{
    //                                 toast('Please take the photo',0)
    //                             }
    //                         }else{
    //                             toast("This form is for 100 level students only",0,6000)
    //                         }
    //                     }else{
    //                         toast("Invalid Matric Number",0,6000)
    //                     }
                        
    //                 }} />
    //                 <Mgin top={20}/>
    //                 <StripBtn txt="REPORT A PROBLEM" onClick={()=>{
    //                     window.location.href = "mailto:fechnologies@gmail.com"
    //                 }} />
    //                 <Mgin top={100}/>
    //                 <mye.Tv text="TouchNut contactless technologies Limited" size={12} color={mye.mycols().hint} />
    //             </div>:
    //             auth_stage==4?
    //             <div style={{
    //                 margin:20,
    //                 backgroundColor:'lavender'
    //             }}>
    //                 <mye.HTv text="Student Verification"/>
    //                 <Mgin top={10}/>
    //                 <mye.Tv text="Please follow the steps below" size={12} color={mye.mycols().hint}  />
    //                 <Mgin top={20}/>
    //                 <Info head="Email verification" body="To verify you are a student, we will confirm your school email (below, not personal email)"/>
    //                 <Mgin top={15}/>
    //                 <mye.BTv text={eml} size={20} />
    //                 <Mgin top={15}/>
    //                 <NumCirc txt="1"/>
    //                 <Mgin top={5}/>
    //                 <mye.Tv text="Get your password on the undergraduate portal. It should be at the bottom of your dashboard" />
    //                 <Mgin top={5}/>
    //                 <Btn txt="GO TO UG PORTAL" onClick={()=>{
    //                     window.open('https://firars.futa.edu.ng')
    //                 }} />
    //                 <Mgin top={25}/>
    //                 <NumCirc txt="2"/>
    //                 <Mgin top={5}/>
    //                 <mye.Tv text="Sign in to this email on Microsoft Outlook (so you can receive the link in step 3)." />
    //                 <Mgin top={5}/>
    //                 <Btn txt="GO TO OUTLOOK" onClick={()=>{
    //                     window.open('https://outlook.office.com')
    //                 }} />
    //                 <Mgin top={25}/>
    //                 <NumCirc txt="3"/>
    //                 <Mgin top={5}/>
    //                 <mye.Tv text={`Once you click the button below, we will send a link to ${eml} (might take up to a min, check your junk folder too). Click the link to confirm your mail and come back here to continue.`} />
    //                 <Mgin top={5}/>
    //                 <Btn txt="GET LINK NOW" onClick={()=>{
    //                     setAuthStage(3)
    //                     if(getAuth().currentUser){
    //                         sendEML(getAuth().currentUser!)
    //                     }else{
    //                         createUserWithEmailAndPassword(getAuth(),eml,"futalib").then((usr)=>{
    //                             sendEML(usr.user)
    //                         }).catch((e)=>{
    //                             signInWithEmailAndPassword(getAuth(),eml,"futalib").then((usr)=>{
    //                                 sendEML(usr.user)
    //                             }).catch((e)=>{
    //                                 console.log(e)
    //                                 setAuthStage(2)
    //                                 toast("An error occured. Please report to fechnologies@gmail.com",0,8000)
    //                             })
    //                         })
    //                     }
    //                 }} />
    //                  <Mgin top={100}/>
    //                  <mye.Tv text="TouchNut LLC" size={12} color={mye.mycols().hint}/>

    //                 {/* <mye.HTv text="Confirmation" />
    //                 <Mgin top={10}/>
    //                 <mye.BTv text={eml} size={16} />
    //                 <Mgin top={10}/>
    //                 <mye.Tv text={`We will send a confirmation link to your email provided by FUTA (above) after you click CONFIRM. You will need to login to this email on Microsoft Outlook (get your password from your dashboard on the undergraduate portal)`} />
    //                 <Mgin top={10}/>
    //                 <Btn txt="CONFIRM"  onClick={()=>{
    //                     setAuthStage(3)
    //                     if(getAuth().currentUser){
    //                         sendEML(getAuth().currentUser!)
    //                     }else{
    //                         createUserWithEmailAndPassword(getAuth(),eml,"futalib").then((usr)=>{
    //                             sendEML(usr.user)
    //                         }).catch((e)=>{
    //                             signInWithEmailAndPassword(getAuth(),eml,"futalib").then((usr)=>{
    //                                 sendEML(usr.user)
    //                             }).catch((e)=>{
    //                                 console.log(e)
    //                                 setAuthStage(2)
    //                                 toast("An error occured. Please report to fechnologies@gmail.com",0,8000)
    //                             })
    //                         })
    //                     }
    //                 }}/> */}
    //             </div>:
    //             <div style={{
    //                 width:dimen.width,
    //                 height:dimen.height,
    //                 display:"flex",
    //                 alignItems:'center',
    //                 justifyContent:'center',
    //                 backgroundColor:"rgba(0,0,0,0.2)"
    //             }}>
    //                 <div style={{
    //                     width:300,
    //                     padding:10,
    //                     borderRadius:10,
    //                     backgroundColor:mye.mycols().bkg
    //                 }}>
    //                     {auth_stage==2?
    //                         <div>
    //                             <mye.HTv text="Student Authentication"/>
    //                             <Mgin top={15}/>
    //                             <EditText hint="Enter your matric number" isNgt={mainprop.isNgt} min={11} max={12} value={mat} recv={(em)=>{
    //                                 setMat(em.trim().toUpperCase())
    //                             }}/>
    //                             <Mgin top={15}/>
    //                             <Btn txt="SUBMIT"  onClick={()=>{
    //                                 if(mat.length <11 ){
    //                                     toast("Invalid Matric Number",0)
    //                                     return;
    //                                 }
    //                                 if(mat.replace(/[^/]/g, "").length==2){
    //                                     if(mat.substring(5,6)=="2"){
    //                                         let nmat = mat.replaceAll('/','')
    //                                         setAuthStage(3)
    //                                         if(localStorage.getItem(mat)!=null){
    //                                             setEml(localStorage.getItem(mat)!)
    //                                             if(getAuth().currentUser && getAuth().currentUser?.emailVerified){
    //                                                 confReg(getAuth().currentUser!)
    //                                             }else{
    //                                                 setAuthStage(4)
    //                                             }
    //                                             return;
    //                                         }
    //                                         get(child(rtdbRef(getDatabase(),'BIZ'),`futa/emails/2022/${nmat}`)).then((snapshot) => {
    //                                             if (snapshot.exists()) {
    //                                               localStorage.setItem(mat,snapshot.val().toString())
    //                                               localStorage.setItem('last_mat',mat)
    //                                               setEml(snapshot.val().toString())
    //                                                 if(getAuth().currentUser && getAuth().currentUser?.emailVerified){
    //                                                     confReg(getAuth().currentUser!)
    //                                                 }else{
    //                                                     setAuthStage(4)
    //                                                 }
    //                                             } else {
    //                                               console.log("No data available");
    //                                               setAuthStage(2)
    //                                               toast("Matric not found. If you are sure, click report a problem",0,6000);
    //                                             }
    //                                           }).catch((error) => {
    //                                             console.error(error);
    //                                             setAuthStage(2)
    //                                             toast("An error occured. Please report to fechnologies@gmail.com if it persists",0,8000)
    //                                           });
    //                                     }else{
    //                                         toast("This form is for 100 level students only",0,6000)
    //                                     }
    //                                 }else{
    //                                     toast("Invalid Matric Number",0)
    //                                 }
    //                             }}/>
    //                             <Mgin top={15}/>
    //                             <StripBtn txt="REPORT A PROBLEM" onClick={()=>{
    //                                 window.location.href = "mailto:fechnologies@gmail.com"
    //                             }} />
    //                         </div>:auth_stage==3?
    //                         <div style={{
    //                             width:"100%",
    //                             height:300,
    //                             display:"flex",
    //                             alignItems:'center',
    //                             justifyContent:'center',
    //                         }}>
    //                             <CircularProgress className="icn" />
    //                         </div>:<div>
    //                             <mye.HTv text="Check Mail" />
    //                             <Mgin top={10}/>
    //                             <mye.Tv text="We have sent a confirmation link to your email. Click it and come back here to continue" />
    //                             <Mgin top={10}/>
    //                             <Btn txt="CONTINUE"  onClick={()=>{
    //                                 getAuth().currentUser!.reload().then(()=>{
    //                                     if(getAuth().currentUser?.emailVerified){
    //                                         confReg(getAuth().currentUser!)
    //                                     }else{
    //                                         toast("Email not yet verified",0)
    //                                     }
    //                                 })
    //                             }}/>
    //                             <Mgin top={10}/>
    //                             <StripBtn txt="RESEND EMAIL" onClick={()=>{
    //                                 sendEML(getAuth().currentUser!)
    //                             }}/>
    //                             <Mgin top={15}/>
    //                             <StripBtn txt="REPORT A PROBLEM" onClick={()=>{
    //                                 window.location.href = "mailto:fechnologies@gmail.com"
    //                             }} />
    //                         </div>
    //                         }
    //                 </div>
    //             </div>:(snap_mode==1 || snap_mode==2)?
    //             <div style={{
    //                 width:dimen.width,
    //                 height:dimen.height,
    //                 position:"relative"
    //             }}>
    //                 <Webcam 
    //                     height={dimen.height}
    //                     width={dimen.width}
    //                     screenshotQuality={1}
    //                     forceScreenshotSourceSize={true}
    //                     screenshotFormat="image/jpeg"
    //                     ref={(snap_mode==1 || snap_mode==2)?webcamRef:null}
    //                     videoConstraints={{
    //                         height:dimen.height,
    //                         width:dimen.width,
    //                         facingMode:"user"
    //                     }}
    //                     audio={false}/>
    //                 <div className="ctr" style={{
    //                     width:dimen.width,
    //                     position:"absolute",
    //                     bottom:10,
    //                     flexDirection:"row"
    //                 }}>
    //                     <div className="ctr" style={{
    //                         width:70,
    //                         height:70,
    //                         borderRadius:40,
    //                         backgroundColor:mye.mycols().btnstrip
    //                     }} onClick={()=>{
    //                         setSnapMode(0)
    //                     }}>
    //                         <Close className="icn" style={{
    //                             color:mye.mycols().blue
    //                         }}/>
    //                     </div>
    //                     <Mgin right={40}/>
    //                     <div className="ctr" style={{
    //                         width:70,
    //                         height:70,
    //                         borderRadius:40,
    //                         backgroundColor:mye.mycols().blue
    //                     }} onClick={()=>{
    //                         console.log(snap_mode)
    //                         const imageSrc = webcamRef.current!.getScreenshot();
    //                         if(snap_mode==1){
    //                             //setIdCard(imageSrc);
    //                         }else{
    //                             setPortrait(imageSrc);
    //                         }
    //                         setSnapMode(snap_mode+2)
    //                     }}>
    //                         <Camera className="icn" style={{
    //                             color:mye.mycols().white
    //                         }}/>
    //                     </div>
    //                 </div>
    //                 <div className="ctr" style={{
    //                     width:dimen.width,
    //                     position:"absolute",
    //                     top:10,
    //                     flexDirection:"row"
    //                 }}>
    //                     <div style={{
    //                         padding:5,
    //                         borderRadius:5,
    //                         backgroundColor:mye.mycols().btnstrip
    //                     }}>
    //                         <mye.Tv text="No camera showing? close and try again" size={12} color={mye.mycols().hint} />
    //                     </div>
    //                 </div>
    //                 <div className="ctr" style={{
    //                     width:dimen.width,
    //                     position:"absolute",
    //                     top:40,
    //                     flexDirection:"row"
    //                 }}>
    //                     <div style={{
    //                         padding:5,
    //                         borderRadius:5,
    //                         backgroundColor:mye.mycols().redstrip
    //                     }}>
    //                         <mye.Tv text="Position yourself at the center and snap from your chest up" size={12} color={mye.mycols().red} />
    //                     </div>
    //                 </div>
    //             </div>:
    //             <div style={{
    //                 width:dimen.width,
    //                 height:dimen.height,
    //                 position:'relative'
    //             }}>
    //                 <img alt="Capture" src={snap_mode==3?portrait /* idcard */:portrait} width={dimen.width} 
    //                 /* height={snap_mode==3?280:dimen.height}*/ />
    //                 <div style={{
    //                     position:'absolute',
    //                     bottom:10,
    //                     width:dimen.width,
    //                 }}>
    //                     <Btn txt="PROCEED" onClick={()=>{
    //                         setSnapMode(0)
    //                     }}/>
    //                     <Mgin top={10}/>
    //                     <StripBtn txt="RETRY" onClick={()=>{
    //                         setSnapMode(snap_mode-2)
    //                     }} />
    //                     <Mgin top={10}/>
    //                     <StripBtn txt="CLOSE" onClick={()=>{
    //                         setSnapMode(0)
    //                     }} />
    //                 </div>
    //             </div>
    //             }
    //     </div>
    // </div>);

    function NumCirc(prop:{txt:string}){
        return <div className="ctr" style={{
            width:35,
            height:35,
            borderRadius:30,
            backgroundColor:mye.mycols().blue,
        }}>
            <mye.BTv text={prop.txt} size={14} color={mye.mycols().white} />
        </div>
    }

    function Icont(prop:{txt:string,ocl:()=>void, src:any}){
        return (
            <div style={{
                width:"100%",
                height:200,
                borderRadius: 10,
                backgroundColor:mye.mycols().btnstrip,
                position:'relative'
            }} onClick={()=>{
                prop.ocl()
            }}>
                {prop.src?<img src={prop.src}  width='100%' height='100%' style={{
                    position:'absolute',
                    borderRadius: 10,
                    top:0,
                }} />:<div></div>}
                <div className="ctr" style={{
                    width:'100%',
                    height:'100%',
                    position:'absolute',
                }}>
                    <div className="inrow">
                        <Add className="icn" />
                        <Mgin right={10}/>
                        <mye.BTv size={14} text={prop.txt}/>
                    </div>
                </div>
            </div>
        )
    }

    function Info(prop:{head:string, body:string}){
        return (
            <div style={{
                borderRadius:5,
                padding:5,
                backgroundColor:mye.mycols().btnstrip5
            }}>
                <mye.BTv text={prop.head} size={14} color={mye.mycols().blue}  />
                <Mgin top={5}/>
                <mye.Tv text={prop.body} color={mye.mycols().hint} />
            </div>
        )
    }

    function confReg(usr:User){
        setAuthStage(3)
        let matric = mat.toUpperCase().replaceAll('/','')
        getFS(docu(getFirestore(),`${getRootDB()}/libreg/${matric}`),(task)=>{
            if(task.isSuccessful() && task.exists()){
                setEml(getData(task.getResult(),'e'))
                setFn(getData(task.getResult(),'f'))
                setSn(getData(task.getResult(),'s'))
                setPhn(getData(task.getResult(),'p'))
                setEditable(getData(task.getResult(),'dn')!='1')
                setComplete(true)
            }else{
                setAuthStage(1)
            }
        })
    }

    function sendEML(usr:User){
        if(usr.emailVerified){
            confReg(usr)
        }else{
            setAuthStage(3)
            sendEmailVerification(usr).then(()=>{
                setAuthStage(5)
            }).catch((e)=>{
                console.log(e)
                setAuthStage(2)
                toast("An error occured. Please report to fechnologies@gmail.com",0,8000)
            })
        }
        
    }

}