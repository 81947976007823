//import logo from './logo.svg';
import './App.css';
import { initializeApp } from "firebase/app";
import {BrowserRouter as Router, Routes, Route, useLocation, useParams, useNavigate} from 'react-router-dom'
import { createTheme, ThemeProvider } from "@mui/material";
import { useEffect, useState } from 'react';
import Futa from './components/damain';
import { BoldText, Btn, Mgin, MyCols, TextBox } from './helper/general';
import useWindowDimensions from './helper/dimension';

let touchnutConfig = {
  apiKey: "AIzaSyDHxPObGGa7QDgiUcn-fWoEt8K0e6EurfE",
  authDomain: "touchnut-e0126.firebaseapp.com",
  projectId: "touchnut",
  storageBucket: "touchnut.appspot.com",
  messagingSenderId: "962170774024",
  appId: "1:962170774024:web:3adc9483b05402c35667bd"
  }
initializeApp(touchnutConfig)

  const theme = createTheme({
    palette: {
        primary:{
            main: '#0411A7'
        },
        secondary:{
            main:'#FFA500'
        },
    }
})

function useQuery(){
  return new URLSearchParams(useLocation().search);
}


export default function App() {
  const[isNgt, setNgt] = useState(false)
  const[isApp, setIsApp] = useState(true)
  //const qry = useQuery();

  useEffect(()=>{
    let murl = window.location.href;
    if(murl.includes('mode=')){
      let url = murl.split('mode=')[1]
      if(url.startsWith('n')){
        setNgt(true)
      }
    }
    setIsApp(murl.includes('app='))
  },[])
 
  return (
    <ThemeProvider theme={theme}>
      <div className='App'>
        <Router>
          <Routes>
            <Route path='/'  element={<Landing  isNgt={isNgt}/>}></Route>
            <Route path='/library'  element={<Futa isApp={isApp} isNgt={isNgt}/>}></Route>
          </Routes>
        </Router>
      </div> 
    </ThemeProvider>
  );
}

function Landing(mainprop:{isNgt:boolean}){ //Improve
  const dimen = useWindowDimensions();

  useEffect(()=>{
    console.log("HALLO")
  },[])

  return <div className='ctr' style={{
    width:dimen.width,
    height:dimen.height,
    padding:5
  }}>
    <BoldText isNgt={mainprop.isNgt} size={20} text={"Welcome To TouchNut"}  />
    <Mgin top={10} />
    <TextBox isNgt={mainprop.isNgt} text={"Contactless solutions for Africa's needs"} color={MyCols(false).hint} size={12}/>
    <Mgin top={10} />
    <Btn txt='SPEAK WITH SALES' onClick={()=>{
      window.location.href = "mailto:contracts@touchnut.com"
    }} />
  </div>

}

